import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { CalciteNavigationLogo } from "@esri/calcite-components-react"
import Logo from "../../../assets/images/logo.png"
import "./siteFooter.scss"

class SiteFooter extends Component {
  constructor(props) {
    super(props)
    this.props = props
  }
  render() {
    const currentYear = new Date().getFullYear()
    return (
      <div className="site-footer">
        <CalciteNavigationLogo
          thumbnail={Logo}
          slot="logo"
          heading={`Black Girls MAPP ©${currentYear}`}
        />
        <ul className="site-footer-social-links">
          {this.props.socialItems.allContentfulSocialLinkNavigation.edges.map(
            (edge) =>
              edge.node.socialLinkItems.map((node, index) => (
                <li key={index}>
                  <OutboundLink
                    href={node.href}
                    rel="noopener noreferrer"
                    target="_blank"
                    title={node.title}
                  >
                    <img
                      src={node.image.file.url}
                      alt={`BGMAPP ${node.title}`}
                    />
                  </OutboundLink>
                </li>
              ))
          )}
        </ul>
      </div>
    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulSocialLinkNavigation {
          edges {
            node {
              socialLinkItems {
                title
                href
                image {
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(socialItems) => (
      <SiteFooter socialItems={socialItems} {...props} />
    )}
  />
)
