import React, { Component, Fragment } from "react"
import { PropTypes } from "prop-types"
import SiteMetaData from "../components/siteComponents/siteMetaData/siteMetaData"
import SiteFooter from "../components/siteComponents/siteFooter/siteFooter"
import "../assets/styles/base.scss"
import {
  applyPolyfills,
  defineCustomElements,
} from "@esri/calcite-components/dist/loader"
import { CalciteShell } from "@esri/calcite-components-react"
import SiteNavigation from "../components/siteComponents/siteNavigation/siteNavigation"
import "@esri/calcite-components/dist/calcite/calcite.css"

export default class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    if (typeof window !== `undefined`) {
      defineCustomElements(window, {
        resourcesUrl: "https://js.arcgis.com/calcite-components/2.8.0/",
      })
    }

    const props = this.props
    const state = this.state
    const content = React.Children.map(props.children, (child) =>
      React.cloneElement(child, { ...props, ...state })
    )
    if (typeof window !== `undefined`) {
      return (
        <Fragment>
          <SiteMetaData {...props} {...state} />
          <CalciteShell>
            <SiteNavigation {...props} {...state} slot="header" />
            {content}
            <SiteFooter {...props} {...state} slot="footer" />
          </CalciteShell>
        </Fragment>
      )
    } else return null
  }
}

Layout.propTypes = {
  children: PropTypes.object,
}
