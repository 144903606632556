import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"
import logo from "../../../assets/images/logo.png"

export class SiteMetaData extends Component {
  constructor(props) {
    super(props)
    this.props = props
  }
  render() {
    const data = this.props.metadata
    const siteTitle = data.site.siteMetadata.title ? `BGMAPP` : "BGMAPP"
    return (
      <Helmet
        title={`${siteTitle}`}
        meta={[
          { name: "description", content: `bgmapp` },
          { name: "keywords", content: `keywords` },
          { name: "viewport", content: "width=device-width, initial-scale=1" },
        ]}
        link={[{ rel: "icon", type: "image/png", href: logo }]}
      >
        <html lang="en" />
        <link rel="icon" type="image/png" href={logo} />
        <link
          href="https://fonts.googleapis.com/css?family=Rubik:400,400i,500,500i,700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://js.arcgis.com/4.8/esri/css/main.css"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(metadata) => <SiteMetaData metadata={metadata} {...props} />}
  />
)
