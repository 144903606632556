import React, { Component, Fragment } from "react"
import { StaticQuery, graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Logo from "../../../assets/images/logo.png"
import "./siteNavigation.scss"
import {
  CalciteNavigation,
  CalciteNavigationLogo,
  CalcitePanel,
  CalciteSheet,
  CalciteMenu,
  CalciteMenuItem,
} from "@esri/calcite-components-react"

class SiteNavigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sheetOpen: false,
    }
  }

  toggleMenu = () => {
    this.setState({
      sheetOpen: !this.state.sheetOpen,
    })
  }

  render() {
    const renderMenuItems = () => {
      return (
        <Fragment>
          {this.props.navItems.allContentfulNavigation.edges.map((edge) =>
            edge.node.navigationItems.map((node, index) => {
              const slugIsHome = node.slug === "/"
              const menuItemIsActive =
                slugIsHome && window.location.pathname === "/"
                  ? true
                  : (!slugIsHome &&
                      window.location.pathname.includes("/" + node.slug)) ||
                    (node.title === "The Hub" &&
                      window.location.pathname.includes("detail"))
                  ? true
                  : undefined
              return (
                <CalciteMenuItem
                  text={node.title}
                  label={node.title}
                  href={slugIsHome ? `/` : `/${node.slug}`}
                  active={menuItemIsActive ? true : undefined}
                  key={index}
                />
              )
            })
          )}
        </Fragment>
      )
    }
    const renderSocialLinks = () => {
      return (
        <Fragment>
          {this.props.navItems.allContentfulSocialLinkNavigation.edges.map(
            (edge) =>
              edge.node.socialLinkItems.map((node, index) => (
                <li key={index}>
                  <OutboundLink
                    href={node.href}
                    rel="noopener noreferrer"
                    target="_blank"
                    title={node.title}
                  >
                    <img
                      src={node.image.file.url}
                      alt={`BGMAPP ${node.title}`}
                    />
                  </OutboundLink>
                </li>
              ))
          )}
        </Fragment>
      )
    }
    return (
      <Fragment>
        <CalciteSheet
          slot="sheets"
          displayMode="float"
          open={this.state.sheetOpen}
          onCalciteSheetClose={this.toggleMenu}
        >
          <CalcitePanel heading="Menu">
            <CalciteMenu layout="vertical">{renderMenuItems()}</CalciteMenu>
            <ul className="site-footer-social-links" slot="footer">
              {renderSocialLinks()}
            </ul>
          </CalcitePanel>
        </CalciteSheet>

        <CalciteNavigation
          navigationAction
          onCalciteNavigationActionSelect={this.toggleMenu}
          slot="header"
        >
          <CalciteNavigationLogo
            thumbnail={Logo}
            slot="logo"
            heading="BGMAPP"
            href="/"
          />
          <CalciteMenu slot="content-start">{renderMenuItems()}</CalciteMenu>
          <ul className="site-footer-social-links" slot="content-end">
            {renderSocialLinks()}
          </ul>
        </CalciteNavigation>
      </Fragment>
    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulSocialLinkNavigation {
          edges {
            node {
              socialLinkItems {
                title
                href
                image {
                  file {
                    url
                  }
                }
              }
            }
          }
        }
        allContentfulNavigation {
          edges {
            node {
              navigationItems {
                slug
                title
              }
            }
          }
        }
      }
    `}
    render={(navItems) => <SiteNavigation navItems={navItems} {...props} />}
  />
)
